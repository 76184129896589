<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="table-responsive">
      <table class="table table-bordered table-striped table-sm">
        <thead class="thead-light">
          <tr>
            <th style="text-align: center">Name</th>
            <th style="text-align: center">Bank</th>
            <th style="text-align: center">Acct. Number</th>
            <th style="text-align: center">Initial Value</th>
            <th style="text-align: center">Current Value</th>
            <th style="text-align: center">Inflow</th>
            <th style="text-align: center">Outflow</th>
            <th style="text-align: center">P&L</th>
            <th style="text-align: center">TWR</th>
            <th style="text-align: center">MWR</th>
          </tr>
        </thead>
        <tbody
          v-for="(client, clientName) in summaryTableData"
          :key="clientName">
          <tr class="bg-secondary" style="text-align: right">
            <th scope="row" style="text-align: left">
              <router-link :to="'/client/' + clientName + '/statement'">
                {{ clientName }}
              </router-link>
            </th>

            <th scope="row" style="text-align: left"></th>
            <th scope="row" style="text-align: left"></th>
            <th scope="row">
              {{
                totals.byClient[clientName].initialValue | numFormat("0,0.00")
              }}
            </th>
            <th scope="row">
              {{
                totals.byClient[clientName].currentValue | numFormat("0,0.00")
              }}
            </th>
            <th scope="row">
              {{ totals.byClient[clientName].inflow | numFormat("0,0.00") }}
            </th>
            <th scope="row">
              {{ totals.byClient[clientName].outflow | numFormat("0,0.00") }}
            </th>
            <th scope="row">
              {{ totals.byClient[clientName].pnl | numFormat("$0,0.00") }}
            </th>
            <th scope="row">
              {{ totals.byClient[clientName].twr | numFormat("0,0.00%") }}
            </th>
            <th scope="row">
              {{ totals.byClient[clientName].mwr | numFormat("0,0.00%") }}
            </th>
          </tr>
          <tr
            v-for="(portfolio, portfolioName) in client"
            style="text-align: right"
            :key="portfolioName">
            <td style="text-align: left; padding-left: 25px">
              <router-link :to="'/portfolio/' + portfolioName + '/statement'">
                {{ portfolioName }}
              </router-link>
            </td>

            <td style="text-align: left">
              {{ portfolio.Bank }}
            </td>
            <td style="text-align: left">
              {{ portfolio.Account_Number }}
            </td>
            <td>
              {{ portfolio.Balance | numFormat("0,0.00") }}
            </td>
            <td>
              {{ portfolio.Current_Portfolio | numFormat("0,0.00") }}
            </td>
            <td>
              {{ portfolio.Inflows | numFormat("0,0.00") }}
            </td>
            <td>
              {{ portfolio.Outflows | numFormat("0,0.00") }}
            </td>
            <td
              :class="{
                'text-danger': portfolio.PNL < 0,
                'text-primary': portfolio.PNL >= 0,
              }">
              {{ portfolio.PNL | numFormat("$0,0.00") }}
            </td>
            <td
              :class="{
                'text-danger': portfolio.TWR < 0,
                'text-primary': portfolio.TWR >= 0,
              }">
              {{ (portfolio.TWR / 100) | numFormat("0,0.00%") }}
            </td>
            <td
              :class="{
                'text-danger': portfolio.MWR < 0,
                'text-primary': portfolio.MWR >= 0,
              }">
              {{ (portfolio.MWR / 100) | numFormat("0,0.00%") }}
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-light">
          <tr style="text-align: right">
            <th scope="row" style="text-align: left">Total</th>
            <th scope="row" style="text-align: left"></th>
            <th scope="row" style="text-align: left"></th>
            <th scope="row">
              {{ totals.general.initialValue | numFormat("0,0.00") }}
            </th>
            <th scope="row">
              {{ totals.general.currentValue | numFormat("0,0.00") }}
            </th>
            <th scope="row">
              {{ totals.general.inflow | numFormat("0,0.00") }}
            </th>
            <th scope="row">
              {{ totals.general.outflow | numFormat("0,0.00") }}
            </th>
            <th scope="row">
              {{ totals.general.pnl | numFormat("$0,0.00") }}
            </th>
            <th scope="row">
              {{ (totals.general.twr / 100) | numFormat("0,0.00%") }}
            </th>
            <th scope="row">
              {{ (totals.general.mwr / 100) | numFormat("0,0.00%") }}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </b-overlay>
</template>

<script>
import { getSummaryView } from "@/services/api.js";

export default {
  data() {
    return {
      summaryTableData: {},
      totals: {
        byClient: {},
        general: {
          initialValue: 0,
          currentValue: 0,
          inflow: 0,
          outflow: 0,
          pnl: 0,
          twr: 0,
          mwr: 0,
        },
      },
    };
  },
  mounted() {
    if (
      this.summaryData !== undefined &&
      this.summaryData !== null &&
      this.summaryData !== ""
    ) {
      this.summaryTableData = this.$store.state.summary_view;
    } else {
      getSummaryView(this.selectedPortfolioType, this.selectedPortfolio).then((response) => {
        this.$store.commit("SET_SUMMARY_VIEW", response.data.data);
        this.summaryTableData = this.$store.state.summary_view;
      });
    }
  },
  computed: {
    loading() {
      return Object.keys(this.summaryTableData).length == 0;
    },
    selectedPortfolio() {
        return this.$store.state.selectedPortfolio;
    },
    selectedPortfolioType() {
        return this.$store.state.selectedPortfolioType;
    },
    summaryData() {
      return this.$store.state.summary_view;
    },
  },
  watch: {
    summaryTableData: {
      handler() {
        this.buildSummaryArray();
      },
    },
    selectedPortfolio() {
        this.summaryTableData = {};
        getSummaryView(this.selectedPortfolioType, this.selectedPortfolio).then((response) => {
            this.$store.commit("SET_SUMMARY_VIEW", response.data.data);
            this.summaryTableData = this.$store.state.summary_view;
        });
    },
  },
  methods: {
    buildSummaryArray: function () {
      this.totals = {
        byClient: {},
        general: {
            initialValue: 0,
            currentValue: 0,
            inflow: 0,
            outflow: 0,
            pnl: 0,
            twr: 0,
            mwr: 0,
        }
      };
      for (const clientName in this.summaryTableData) {
        this.totals.byClient[clientName] = {
          initialValue: 0,
          currentValue: 0,
          inflow: 0,
          outflow: 0,
          pnl: 0,
          twr: 0,
          mwr: 0,
        };

        for (const portfolio in this.summaryTableData[clientName]) {
          this.totals.byClient[clientName].initialValue += parseFloat(
            this.summaryTableData[clientName][portfolio]["Balance"]
          );
          this.totals.byClient[clientName].currentValue += parseFloat(
            this.summaryTableData[clientName][portfolio]["Current_Portfolio"]
          );
          this.totals.byClient[clientName].inflow += parseFloat(
            this.summaryTableData[clientName][portfolio]["Inflows"]
          );
          this.totals.byClient[clientName].outflow += parseFloat(
            this.summaryTableData[clientName][portfolio]["Outflows"]
          );
          this.totals.byClient[clientName].pnl += parseFloat(
            this.summaryTableData[clientName][portfolio]["PNL"]
          );
        }
        this.totals.byClient[clientName].twr =
          this.totals.byClient[clientName].pnl /
          this.totals.byClient[clientName].initialValue;

        // Grand Total Row
        this.totals.general.initialValue +=
          this.totals.byClient[clientName]["initialValue"];
        this.totals.general.currentValue +=
          this.totals.byClient[clientName]["currentValue"];
        this.totals.general.inflow +=
          this.totals.byClient[clientName]["inflow"];
        this.totals.general.outflow +=
          this.totals.byClient[clientName]["outflow"];
        this.totals.general.pnl += this.totals.byClient[clientName]["pnl"];
        this.totals.general.twr =
          (this.totals.general.pnl / this.totals.general.initialValue) * 100;
      }
    },
  },
};
</script>

<style>
.table tbody + tbody {
  border-top: 0;
  border-bottom: 0;
}
</style>
