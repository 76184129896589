<template>
  <ShadowCard
    :param="{
      name: 'Summary View',
    }"
  >
    <SummaryTable />
  </ShadowCard>
</template>

<script>

import ShadowCard from "@/presentationalComponents/ShadowCard.vue";
import SummaryTable from "./SummaryTable";

export default {
  name: "PortfolioViewPrincipal",
  components: {
    SummaryTable,
    ShadowCard,
  },
};
</script>

<style scoped></style>
