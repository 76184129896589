import { render, staticRenderFns } from "./SummaryView.vue?vue&type=template&id=c836995e&scoped=true"
import script from "./SummaryView.vue?vue&type=script&lang=js"
export * from "./SummaryView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c836995e",
  null
  
)

export default component.exports